import React, { useState, useContext, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {  toast } from 'react-toastify';

import { Context } from '../contexts/MainContext'
import { UploadService } from '../service/UploadService'
import { UserService } from '../service/UserService'
import Spinner from '../components/Spinner';
import lgadata from '../data/lga'

import { AgentService } from '../service/AgentService'
import { NotificationService } from '../service/NotificationService';


function Register({aggregator, completeReg}) {
  const { changeProgress } = useContext(Context);

  
  const [info, setInfo] = useState("")
  const [complete, setComplete] = useState(0)
  const [lgas, setLgas] = useState("");
  const [busy, setBusy] = useState(true)
  const [banks, setBanks] = useState([])

  const phoneRegExp = /^[0]\d{10}$/

  const sas = process.env.REACT_APP_SAS;

  const bankOptions = banks.map(bank => {
    return(
        <option key={bank.bankCode} value={`${bank.bankCode}`}>{`${bank.bankName}`}</option>
    )
})

  function updateBusy() {
    setBusy(prev => {
      return !prev
    })
  }

  function notify (message) {
     toast(message, 
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
     });
  }

  function notifyError(message, duration){
    toast.error(message, {
      position: "top-right",
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  useEffect(() => {
    
    setInfo(prev => {
      return "Please fill the form completely"
    })

    AgentService.getBankList()
    .then(response => {
        
        setBanks(prev => {
            return response.data.data.banks
        })
        setBusy(false)
    })
    .catch(error => {
        if(error.response){
            NotificationService.notifyError(error.response.data.message, 5000)
        }
        else if(error.message){
            NotificationService.notifyError(error.message, 5000)
        }
        else{
            NotificationService.notifyError("Network Connectivity Problems, please check your network", 5000)
        }
        setBusy(false)
    })
  }, [complete,lgas])

  function updateInfo(message) {
    setInfo(prev => {
      return message
    })
  }

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      businessname: '',
      password: '',
      confirm: '',
      email: '',
      phonenumber: '',
      bvn: 0,
      bankCode: '',
      address: '',
      lga: '',
      state: '',
      accountname: '',
      accountnumber: 0,
      usertype: '',
      passportphoto: null,
      cacdoc: null,
      identification: null,
      proofofaddress: null,
      acceptTerms: false
    },
    validate: ((values) => {
      
    }),
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required('Please enter your First Name'),
      lastname: Yup.string()
        .required('Please enter your Last Name'),
      businessname: Yup.string()
        .required('Please enter your Business Name'),
      email: Yup.string()
        .email('Invalid Email Address')
        .required('Please enter your email address'),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required('Please enter your password'),
      confirm: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        )
      }),
      phonenumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid'),
      //.test('len', 'Must be exactly 11 characters', val => val.length === 11),
      bvn: Yup.string()
        .required('Please enter your BVN')
        .test('len', 'Please enter a valid BVN', val => !val || val.length === 11),
      bankCode: Yup.string()
        .required('Please select a Bank from the list'),
      address: Yup.string()
        .required('Please enter your street address and number'),
      state: Yup.string()
        .required('Please select a State from the list'),
      lga: Yup.string()
        .required('Please select a Local Government Area from the list'),
      usertype: Yup.string()
        .required('Please select the category you want to register with on Surebanka'),
      accountname: Yup.string()
        .required('Please enter your account name'),
      accountnumber: Yup.string()
        .required('Please enter your account number')
        .test('len', 'Please use a 10 digit NUBAN', val => !val || val.length === 10),
      passportphoto: Yup.mixed()
        .required("Please select your passport photo")
        .test(
          "fileSize",
          "Your passport photo size is too big :(",
          value => value && value.size <= 11571000
        ),
      cacdoc: Yup.mixed()
        .required("Please select your CAC Registration Document.")
        .test(
          "fileSize",
          "Your CAC Document size is too big :(",
          value => value && value.size <= 11571000
        ),
      identification: Yup.mixed()
        .required("Please select your identification document.")
        .test(
          "fileSize",
          "The document size is too big :(",
          value => value && value.size <= 11571000
        ),
      proofofaddress: Yup.mixed()
        .required("Please select your proof of address.")
        .test(
          "fileSize",
          "The document size is too big :(",
          value => value && value.size <= 11571000
        ),
        acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required.')

    }),
    onSubmit: values => {
      updateBusy();
      UploadFileSync(values)

    }
  });

  async function UploadFileSync(values) {
    const bank = banks.find(bank => bank.bankCode === values.bankCode)
    console.log(bank)
    try {
      const passportResponse = await UploadService.uploadFile(values.passportphoto)
      const fullUrl = passportResponse._response.request.url;
      const passportphoto = getFileName(fullUrl);

      const cacdocResponse = await UploadService.uploadFile(values.cacdoc)
      const cacdoc = getFileName(cacdocResponse._response.request.url);

      const identificationResponse = await UploadService.uploadFile(values.identification)
      const identification = getFileName(identificationResponse._response.request.url);

      const proofofaddressResponse = await UploadService.uploadFile(values.proofofaddress)
      const proofofaddress = getFileName(proofofaddressResponse._response.request.url);

      

      UserService.register({ ...values,bankCode: values.bankCode, bankname: bank.bankName, accountnumber: values.accountnumber.toString(), passportphoto, proofofaddress, identification, aggregator, businessName: values.businessname, cacdocument: cacdoc })
        .then((response) => {
          notify("The Agent was added successfully, email activation is the next step for the Agent")
          changeProgress(100)
          completeReg();
          
        })
        .catch((error) => {
          console.log(error)
          updateBusy();
          changeProgress(100)
          if (error.response) {
            notifyError(error.response.data.message, 2000)
            updateInfo(error.response.data.message, 2000)
          }
          else{
            notifyError("An network/connection issue has occured, please check your internet connection", 2000)
            updateInfo("An network/connection issue has occured, please check your internet connection", 2000);
          }
        })
    }
    catch (error) {
      
      changeProgress(100)
    }
  }

  function getFileName(url) {
    // Match everything between the last / and ? 
    const fileName = url.match(/\/([^\/]+)\?/)[1];
    // Decode the URL encoding if needed
    return decodeURIComponent(fileName);
  }
  

  function filterLocal(e){
    formik.handleChange(e)
    const state = e.target.value
    const local = lgadata[state]
    
    
      if(local){
      const options = local.map(l => {
        return (<option key={l} value={`${l}`}>{`${l}`}</option>)      
      })
      setLgas(prev => {
        return options;
      })
    }
    else{
      return;
    }
  }



  return (
    <>
      
      {busy ? <Spinner /> :
              <>
                  <div className="pd-x-40">
                    <h2 className="signin-title-primary tx-600 dark-color pd-t-40">User Onboarding</h2>

                  </div>

                  <div>

                    <p className="signupHeader ">Basic information</p>
                    

                  </div>
                  <div className="">
                    <div className="alert" id="status-div"></div>


                    <form autoComplete="off" onSubmit={formik.handleSubmit}  >
                      <div className="floating-form ">
                        <div className="pd-x-40">
                          <p className=""><a href=""> <span className="pss-red">{info}</span></a> </p>
                        </div>
                      </div>
                      <div className="floating-form ">
                        <div className="pd-x-40">
                          <div className="floating-label ">
                            <input className="floating-input" type="text" placeholder=" " name="firstname" autoComplete="none" autoFocus {...formik.getFieldProps('firstname')} />
                            <span className="highlight"></span>
                            <label>First Name</label>
                          </div>
                          {formik.touched.firstname && formik.errors.firstname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.firstname}</span></a> </p>) : null}
                        </div>
                        <div className="pd-x-40 pd-b-40">

                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="lastname" autoComplete="none" {...formik.getFieldProps('lastname')} />
                            <span className="highlight"></span>
                            <label>Last Name</label>
                          </div>
                          {formik.touched.lastname && formik.errors.lastname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.lastname}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="businessname" autoComplete="none" {...formik.getFieldProps('businessname')} />
                            <span className="highlight"></span>
                            <label>Business Name</label>
                          </div>
                          {formik.touched.businessname && formik.errors.businessname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.businessname}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="email" placeholder=" " name="email" autoComplete="none" {...formik.getFieldProps('email')} />
                            <span className="highlight"></span>
                            <label>Email Address</label>
                          </div>
                          {formik.touched.email && formik.errors.email ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.email}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="password" placeholder=" " name="password" {...formik.getFieldProps('password')} />
                            <span className="highlight"></span>
                            <label>Password</label>
                          </div>
                          {formik.touched.password && formik.errors.password ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.password}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="password" placeholder=" " name="confirm" {...formik.getFieldProps('confirm')} />
                            <span className="highlight"></span>
                            <label>Confirm Password</label>
                          </div>
                          {formik.touched.confirm && formik.errors.confirm ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.confirm}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="phonenumber" {...formik.getFieldProps('phonenumber')} />
                            <span className="highlight"></span>
                            <label>Phone Number</label>
                          </div>
                          {formik.touched.phonenumber && formik.errors.phonenumber ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.phonenumber}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="address" autoComplete="none" {...formik.getFieldProps('address')} />
                            <span className="highlight"></span>
                            <label>Address</label>
                          </div>
                          {formik.touched.address && formik.errors.address ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.address}</span></a> </p>) : null}


                          <div className="floating-label ">
                            <select onChange={e => filterLocal(e)} className="floating-input" placeholder="" name="state" value={formik.values.state} >
                              <option value="" label="Select a State" />
                              <option value="Abuja">Abuja</option>
                              <option value="Abia">Abia</option>
                              <option value="Adamawa">Adamawa</option>
                              <option value="Akwa Ibom">Akwa Ibom</option>
                              <option value="Anambra">Anambra</option>
                              <option value="Bauchi">Bauchi</option>
                              <option value="Bayelsa">Bayelsa</option>
                              <option value="Benue">Benue</option>
                              <option value="Borno">Borno</option>
                              <option value="Cross River">Cross River</option>
                              <option value="Delta">Delta</option>
                              <option value="Ebonyi">Ebonyi</option>
                              <option value="Edo">Edo</option>
                              <option value="Ekiti">Ekiti</option>
                              <option value="Enugu">Enugu</option>
                              <option value="Gombe">Gombe</option>
                              <option value="Imo">Imo</option>
                              <option value="Jigawa">Jigawa</option>
                              <option value="Kaduna">Kaduna</option>
                              <option value="Kano">Kano</option>
                              <option value="Katsina">Katsina</option>
                              <option value="Kebbi">Kebbi</option>
                              <option value="Kogi">Kogi</option>
                              <option value="Kwara">Kwara</option>
                              <option value="Lagos">Lagos</option>
                              <option value="Nassarawa">Nassarawa</option>
                              <option value="Niger">Niger</option>
                              <option value="Ogun">Ogun</option>
                              <option value="Ondo">Ondo</option>
                              <option value="Osun">Osun</option>
                              <option value="Oyo">Oyo</option>
                              <option value="Plateau">Plateau</option>
                              <option value="Rivers">Rivers</option>
                              <option value="Sokoto">Sokoto</option>
                              <option value="Taraba">Taraba</option>
                              <option value="Yobe">Yobe</option>
                              <option value="Zamfara">Zamfara</option>
                            </select>
                            <span className="highlight"></span>
                            <label>State</label>                            
                          </div>
                          {formik.touched.state && formik.errors.state ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.state}</span></a> </p>) : null}
                          
                          <div className="floating-label ">
                            <select  className="floating-input" placeholder="" name="lga" {...formik.getFieldProps('lga')} >
                              <option value="" label="Select a Local Govt" />
                              {lgas}                              
                            </select>
                            <span className="highlight"></span>
                            <label>Local Government</label>                           
                          </div>
                          {formik.touched.lga && formik.errors.lga ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.lga}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="number" placeholder=" " name="bvn" {...formik.getFieldProps('bvn')} />
                            <span className="highlight"></span>
                            <label>BVN</label>
                          </div>
                          {formik.touched.bvn && formik.errors.bvn ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.bvn}</span></a> </p>) : null}

                          <div className="floating-label ">
                            <select className="floating-input" placeholder="" name="bankname" {...formik.getFieldProps('usertype')} >
                              <option value="" label="Select a User Type" />
                              <option value="Agent">Agent</option>
                              <option value="Aggregator">Aggregator</option>
                            </select>
                            <span className="highlight"></span>
                            <label>Registration/User Type</label>
                          </div>
                          {formik.touched.usertype && formik.errors.usertype ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.usertype}</span></a> </p>) : null}



                        </div>
                      </div>

                      <div>
                        <p className="signupHeader ">Cashout details</p>
                        
                      </div>

                      <div className="floating-form ">
                        <div className="pd-x-40">
                          <div className="floating-label ">
                            <select className="floating-input" placeholder="" name="bankCode" {...formik.getFieldProps('bankCode')} >
                              <option value="" label="Select a Bank" />
                              {bankOptions}
                            </select>
                            <span className="highlight"></span>
                            <label>Bank Name</label>
                          </div>
                          {formik.touched.bankname && formik.errors.bankname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.bankname}</span></a> </p>) : null}
                        </div>

                        <div className="pd-x-40 pd-b-40">
                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="accountnumber" {...formik.getFieldProps('accountnumber')} />
                            <span className="highlight"></span>
                            <label>Account Number</label>
                          </div>
                          {formik.touched.accountnumber && formik.errors.accountnumber ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.accountnumber}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="text" placeholder=" " name="accountname" {...formik.getFieldProps('accountname')} />
                            <span className="highlight"></span>
                            <label>Account Name</label>
                          </div>
                          {formik.touched.accountname && formik.errors.accountname ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.accountname}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="file" name="passportphoto" accept="image/*"
                              onChange={event => {
                                formik.setFieldValue("passportphoto", event.currentTarget.files[0])
                              }}
                            />
                            <span className="highlight"></span>
                            <label>Upload Passport</label>
                          </div>
                          {formik.errors.passportphoto ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.passportphoto}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="file" name="cacdoc" accept="image/*"
                              onChange={event => {
                                formik.setFieldValue("cacdoc", event.currentTarget.files[0])
                              }}
                            />
                            <span className="highlight"></span>
                            <label>CAC Document</label>
                          </div>
                          {formik.errors.cacdoc ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.cacdoc}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="file" name="identification" accept="image/*"
                              onChange={event => {
                                formik.setFieldValue("identification", event.currentTarget.files[0])
                              }}
                            />
                            <span className="highlight"></span>
                            <label>Upload Identification Document</label>
                          </div>
                          {formik.errors.identification ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.identification}</span></a> </p>) : null}

                          <div className="floating-label">
                            <input className="floating-input" type="file" name="proofofaddress" accept="image/*"
                              onChange={event => {
                                formik.setFieldValue("proofofaddress", event.currentTarget.files[0])
                              }}
                            />
                            <span className="highlight"></span>
                            <label>Upload Utility Bill or Receipt</label>
                          </div>
                          {formik.errors.proofofaddress ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.proofofaddress}</span></a> </p>) : null}

                          
                          <div className="">
                            <input type="checkbox" name="acceptTerms" {...formik.getFieldProps('acceptTerms')} />
                            
                            <label className="tran">Accept Terms & Conditions. <a target="_blank" href="https://surebanka.blob.core.windows.net/docs/terms.docx" download>Click here to download the Terms and Conditions</a></label>
                            
                          </div>
                          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (<p className="forgot-pass"><a href=""> <span className="pss-red">{formik.errors.acceptTerms}</span></a> </p>) : null}
                         
                          <div className="floating-label">
                            <button className="btn-fill-md majorButton" type="submit" disabled={!formik.isValid && formik.isSubmitting}>Register</button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                {/* </div> */}
                </>
              // </div>


            
      }
      
        </>
  )
}

export default Register